import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiSlice } from 'features/api/apiSlice';
import { setCachedToken } from 'features/auth/authSlice';
import { selectCacheTokens } from 'features/auth/selectors';
import { RootState } from 'lib/centralStore';
import { GamesUrlLancioGiochiResponseDto } from 'types/swagger';

export const getExternalHasedToken = createAsyncThunk(
  'getExternalHasedToken',
  async (
    { codicePiattaforma, codeGame, isInFrame }: { codicePiattaforma: number; codeGame?: string; isInFrame: boolean },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const cachedToken = selectCacheTokens(state)[codicePiattaforma];

    if (cachedToken && isInFrame) {
      return Promise.resolve(cachedToken);
    }

    try {
      return await dispatch(
        apiSlice.endpoints.getExternalToken.initiate({ codicePiattaforma, codeGame }, { forceRefetch: true })
      )
        .unwrap()
        .then(({ externalTokenSgp: externalToken }) => {
          if (externalToken) {
            dispatch(setCachedToken({ [codicePiattaforma]: externalToken }));
            return externalToken;
          }
          return null;
        });
    } catch (error) {
      console.error(`There was an error`, error);
      return null;
    }
  }
);

type GetUrlLancioGiochiRequest = {
  codicePiattaforma: number;
  gameCode: string;
  username: string;
  modalita?: string;
  isDemo?: boolean;
};

export const getUrlLancioGioco = createAsyncThunk<GamesUrlLancioGiochiResponseDto, GetUrlLancioGiochiRequest>(
  'getUrlLancioGioco',
  async ({ codicePiattaforma, gameCode, username, modalita, isDemo }, { dispatch }) => {
    return await dispatch(
      apiSlice.endpoints.getUrlLaunchGame.initiate(
        {
          codPiattaforma: codicePiattaforma,
          gameCodeName: gameCode,
          username,
          modalita,
          playMode: isDemo ? 0 : 1,
        },
        { forceRefetch: true }
      )
    ).unwrap();
  }
);
