import { isMatch } from 'utility/functions';
import policy from './types';

const [name, domain] = `${process.env.NEXT_PUBLIC_API_BASE_URL}`.split(/\.|\//gim).slice(3);
const { NONCE_PLACEHOLDER, techs } = policy;

export const formatSources = (key, list, nonce) => {
  // exclude all sources matching *.snai.it | *.happybet.com | *.happybet.de
  const externals = (list ?? []).filter((x) => !isMatch(x, `\\.${name}\\.${domain}`));

  let idx = externals.indexOf(NONCE_PLACEHOLDER);
  while (idx > -1) {
    externals.splice(idx, 1, nonce);
    idx = externals.indexOf(NONCE_PLACEHOLDER);
  }

  const result = [key, ...techs, `*.${name}.${domain}`, ...externals]
    .filter((x) => (x ?? '').length > 0 && (`${x}`.match(/^(null|undefined)$/gim) ?? []).length < 1)
    .map((x) => {
      const url = `${x}?`.split('?');
      const parts = `${url[0]}/`.split('/').filter((x) => !!x);
      if (parts[0].toLowerCase().includes('http')) {
        parts.shift();
      }
      return parts[0] ?? '';
    })
    .filter((value, index, list) => value.length > 0 && list.indexOf(value) === index)
    .join(' ');

  // console.log(result);

  return result;
};
