import Persist, { StorageKind } from 'lib/persist';
import { NextRequest, NextResponse } from 'next/server';

import policy from './types';
import { formatSources } from './utility';
const {
  connections,
  defaults,
  fonts,
  frameAncestors,
  frames,
  images,
  media,
  scriptElems,
  scripts,
  styles,
  formActions,
  objects,
} = policy;

export const NONCE_POLICY_KEY = 'x-nonce';
export const SHA_POLICY_KEY = 'x-sha256';
export const POLICY_KEY = 'Content-Security-Policy';

export const getNonce = () => Persist(StorageKind.cookie).getItem(NONCE_POLICY_KEY) ?? undefined;

export function policyHeader(request: NextRequest, response: NextResponse) {
  const nonce = btoa(crypto.randomUUID());
  const srcNonce = `nonce-${nonce}`;

  const policy = `
    ${formatSources('default-src', defaults, srcNonce)};
    ${formatSources('connect-src', connections, srcNonce)};
    ${formatSources('script-src', scripts, srcNonce)};
    ${formatSources('script-src-elem', scriptElems, srcNonce)};
    ${formatSources('style-src', styles, srcNonce)};
    ${formatSources('frame-src', frames, srcNonce)};
    ${formatSources('media-src', media, srcNonce)};
    ${formatSources('font-src', fonts, srcNonce)};
    ${formatSources('img-src', images, srcNonce)};
    ${formatSources('form-action', formActions, srcNonce)};
    ${formatSources('base-uri', [], srcNonce)};
    ${formatSources('frame-ancestors', frameAncestors, srcNonce)};
    ${formatSources('object-src', objects, srcNonce)};
    block-all-mixed-content;
    upgrade-insecure-requests;`
    .replace(/\s{2,}/g, ' ')
    .trim();

  const requestHeaders = new Headers(request.headers);
  requestHeaders.set(NONCE_POLICY_KEY, nonce);
  requestHeaders.set(POLICY_KEY, policy);

  response.cookies.set(NONCE_POLICY_KEY, nonce);
  response.headers.set(POLICY_KEY, policy);
}
